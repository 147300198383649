import { MIMETypes } from './enum/common';

export const API_URL = import.meta.env.VITE_API_URL;

const docsUrl: string = import.meta.env.VITE_DOCS_URL || 'https://docs.cloud.deepset.ai/';

// make v2 docs the default
export const DOCS_URL = `${docsUrl}v2.0/`;

export const DOCS_URL_V1 = `${docsUrl}v1.0/`;

export enum UploadFileType {
  pdf = 'pdf',
  txt = 'txt',
  csv = 'csv',
  docx = 'docx',
  doc = 'doc',
  pptx = 'pptx',
  xlsx = 'xlsx',
  xls = 'xls',
  xml = 'xml',
  html = 'html',
  md = 'md',
  json = 'json',
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  gif = 'gif',
  webp = 'webp',
  svg = 'svg',
  mp4 = 'mp4',
}

export const FILE_TYPE_TO_MIME_MAP = {
  pdf: MIMETypes.PDF,
  txt: MIMETypes.TXT,
  csv: MIMETypes.CSV,
  docx: MIMETypes.DOCX,
  doc: MIMETypes.DOC,
  pptx: MIMETypes.PPTX,
  xlsx: MIMETypes.XLSX,
  xls: MIMETypes.XLS,
  xml: MIMETypes.XML,
  html: MIMETypes.HTML,
  md: MIMETypes.MD,
  json: MIMETypes.JSON,
  png: MIMETypes.PNG,
  jpg: MIMETypes.JPG,
  jpeg: MIMETypes.JPEG,
  gif: MIMETypes.GIF,
  webp: MIMETypes.WEBP,
  svg: MIMETypes.SVG,
  mp4: MIMETypes.MP4,
};

export const baseHttpStaticText = {
  sessionExpMsg: 'Session token has been expired. Log in again.',
  compareErrMsg: 'Could not validate your credentials',
  notAuth: 'Not authenticated',
};
