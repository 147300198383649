import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from 'antd';
import { StatusCodes } from '@constants/enum/common';
import { PipelineType } from '@constants/pipelines';
import { ANSWER_TITLE, REFERENCES_HEADER } from '@constants/search';
import { searchResultSelector } from '@redux/selectors/searchSelectors';
import { ISearchResult } from '@redux/types/types';
import useReferences from '@components/search/hooks/useReferences';
import ResultContainer from '@components/search/organisms/result/ResultContainer';
import styles from './referenceDrawer.module.scss';
import ReferencesList from '../referencesList/ReferencesList';

interface IReferenceDrawerProps {
  resultExt?: ISearchResult | null;
  isExternal?: boolean;
}

const ReferenceDrawer = (props: IReferenceDrawerProps) => {
  // Only to support shared prototype
  const { resultExt, isExternal } = props;

  const resultInt = useSelector(searchResultSelector);
  const {
    referenceDrawerVisible,
    getSearchResultReferences,
    findActiveReferenceAnswer,
    resetReferenceDrawer,
  } = useReferences();

  const searchResult = resultExt || resultInt;

  const resultReferences = getSearchResultReferences(searchResult);

  // Functions

  const closeDrawer = () => {
    resetReferenceDrawer();
  };

  // Renders

  const renderAnswer = () => {
    const answer = findActiveReferenceAnswer(searchResult.answers);
    const { documents } = searchResult;

    if (!answer) return null;
    return (
      <div className={styles.section}>
        <div className={styles.section_subheader}>
          <h5>{ANSWER_TITLE}</h5>
        </div>
        <div className={styles.answerContainer}>
          <ResultContainer
            pipelineId=""
            queryId=""
            searchStatus={StatusCodes.IDLE}
            searchResult={answer}
            documents={documents}
            pipelineName="pipelineName"
            pipelineType={PipelineType.GENERATIVE_QUESTION_ANSWERING}
            displayFeedbackOptions={false}
            displayFileOptions
            displayFileSources={false}
            displayMoreOptions={false}
            displayReferencesPopover={false}
          />
        </div>
      </div>
    );
  };

  if (!resultReferences) return null;

  return (
    <div>
      <Drawer
        title={REFERENCES_HEADER}
        placement="right"
        open={referenceDrawerVisible}
        closable
        onClose={closeDrawer}
        width={800}
      >
        <div className={styles.body}>
          {renderAnswer()}
          <ReferencesList
            searchResultReferences={resultReferences}
            searchDocuments={searchResult.documents}
            isExternal={isExternal}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ReferenceDrawer;
