import defaultTheme from '@styles/themes/default';
import { ChartArtifactData } from '../types/artifacts';

export const mapArtifactDataToEChartsOption = (chartData: ChartArtifactData) => {
  const baseOption = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      orient: 'horizontal',
      align: 'left',
      left: 0,
      textStyle: {
        color: defaultTheme!.token!.colorTextSecondary,
      },
    },
    grid: {
      left: 0,
      right: 10,
      containLabel: true,
    },
  };

  switch (chartData.type) {
    case 'bar':
      return {
        ...baseOption,
        xAxis: {
          type: 'category',
          data: chartData.labels,
        },
        yAxis: {
          type: 'value',
        },
        series: chartData.datasets.map((dataset) => ({
          type: 'bar',
          name: dataset.label,
          data: dataset.data,
          color: dataset.backgroundColor,
        })),
      };
    case 'line':
      return {
        ...baseOption,
        xAxis: {
          type: 'category',
          data: chartData.labels,
        },
        yAxis: {
          type: 'value',
        },
        series: chartData.datasets.map((dataset) => ({
          type: 'line',
          name: dataset.label,
          data: dataset.data,
          smooth: true,
          lineStyle: {
            width: 3,
          },
          symbolSize: 6,
          color: dataset.borderColor,
        })),
      };
    case 'pie':
    case 'doughnut':
      return {
        ...baseOption,
        series: [
          {
            type: 'pie',
            radius: chartData.type === 'doughnut' ? ['40%', '70%'] : '70%',
            data: chartData.labels.map((label, index) => ({
              name: label,
              value: chartData.datasets[0].data[index],
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    case 'radar':
      return {
        ...baseOption,
        radar: {
          indicator: chartData.labels.map((label) => ({
            name: label,
            max: Math.max(...chartData.datasets[0].data),
          })),
        },
        series: [
          {
            type: 'radar',
            data: chartData.datasets.map((dataset) => ({
              name: dataset.label,
              value: dataset.data,
            })),
          },
        ],
      };
    default:
      return baseOption;
  }
};
