import { ReactNode } from 'react';

export enum ArtifactType {
  CHART = 'chart',
}

export interface ArtifactMetadata {
  type: ArtifactType;
  source: {
    prompt?: string;
  };
  metadata?: Record<string, unknown>;
}

export interface BaseArtifact<T = unknown> {
  metadata: ArtifactMetadata;
  data: T;
  render(): ReactNode;
}

// Artifacts

export type ChartType = 'bar' | 'line' | 'pie' | 'doughnut' | 'radar';

export interface ChartArtifactData {
  type: ChartType;
  title: string;
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor?: string | string[];
    borderColor?: string | string[];
    borderWidth?: number;
  }[];
}
