import React, { useEffect, useMemo, useState } from 'react';
import { FileTextFilled } from '@ant-design/icons';
import { Button, Collapse, Divider, Tabs, Tooltip } from 'antd';
import { groupBy } from 'lodash';
import { getFileExtension } from '@utils/common';
import { parseFileContentToViewer } from '@utils/file';
import { interpolateString } from '@utils/string';
import { getFileMetaApi, getFilePreviewBlobByTypeApi } from '@api/data';
import useFilePreviewFormat from '@hooks/useFilePreviewFormat';
import {
  FILE_DRAWER_COLLAPSE_TABS,
  RAW_FORMAT_LABEL,
  VIEW_METADATA_BUTTON_LABEL,
} from '@constants/common';
import { UploadFileType } from '@constants/constant';
import { SupportedViewerLanguage } from '@constants/enum/common';
import { SOURCES_FILE_LABEL } from '@constants/search';
import {
  FileDrawerCollapseTabOptions,
  IFilePreview,
  ISearchResultDocument,
} from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import SwitchWithText from '@components/common/switchWithText/SwitchWithText';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import MetadataModal from '@components/metadataModal/MetadataModal';
import styles from './sourcesListCollapsePreview.module.scss';

const PDFHighlighter = React.lazy(() => import('@components/pdf/PDFHighligther'));
const CodeViewer = React.lazy(() => import('@components/codeViewer/CodeViewer'));

const { Panel } = Collapse;

interface ISourcesListCollapsePreviewProps {
  documents: ISearchResultDocument[];
  onTabChange?: ({ tab, fileId }: { tab: string; fileId: string }) => void;
}

const SourcesListCollapsePreview = ({
  documents,
  onTabChange,
}: ISourcesListCollapsePreviewProps) => {
  const { supportedRawFilePreviewLanguagues, showRawCodeFormat, setShowRawCodeFormat } =
    useFilePreviewFormat();
  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);
  const [currentModalMetaData, setCurrentModalMetaData] = useState({});
  const [currentActiveCollapseTab, setCurrentActiveCollapseTab] = useState<
    FileDrawerCollapseTabOptions | string
  >(FILE_DRAWER_COLLAPSE_TABS[1].key);
  const [selectedFileID, setSelectedFileID] = useState('');
  const [loadingFileContent, setLoadingFileContent] = useState<boolean>(false);
  const [filePreview, setFileContent] = useState<IFilePreview>();

  const documentsGroupedByFileId = groupBy(documents, 'file.id');

  const getGroupedDocumentsFileName = (id: string) => documentsGroupedByFileId[id][0]?.file?.name;

  const getFileContentById = async (fileId: string) => {
    try {
      setLoadingFileContent(true);
      const fileName = getGroupedDocumentsFileName(fileId);
      const fileType = getFileExtension(fileName) as UploadFileType;
      const { data } = await getFilePreviewBlobByTypeApi(fileId, fileType);
      const { data: metaData } = await getFileMetaApi(fileId);

      const parsedFileContent = await parseFileContentToViewer(data);

      setFileContent(parsedFileContent);
      setCurrentModalMetaData({ ...metaData, file_id: fileId });
      setLoadingFileContent(false);
    } catch {
      setLoadingFileContent(false);
    }
  };

  useEffect(() => {
    if (currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE && selectedFileID)
      getFileContentById(selectedFileID);
  }, [currentActiveCollapseTab, selectedFileID]);

  // Methods
  const showRawCodeFormatSwitch = () => {
    if (loadingFileContent || !filePreview?.displayLanguage) return false;
    return supportedRawFilePreviewLanguagues.includes(filePreview.displayLanguage);
  };

  const onFileCollapseChange = (key: string[]) => {
    const [fileId] = key;
    setSelectedFileID(fileId || '');
  };

  // Renders
  const renderMetaDataModal = () => {
    return (
      <MetadataModal
        data={currentModalMetaData}
        nonEditableFields={['file_id']}
        open={isMetaModalVisible}
        onCancel={() => setIsMetaModalVisible(false)}
        onOk={() => setIsMetaModalVisible(false)}
      />
    );
  };

  const renderPDFContent = useMemo(() => {
    if (!filePreview || !(filePreview.content instanceof ArrayBuffer)) return null;
    const { content } = filePreview;
    return (
      <React.Suspense fallback={<LoadingIndicator />}>
        <PDFHighlighter pdfData={content} />
      </React.Suspense>
    );
  }, [filePreview]);

  const renderTextContent = (content: string) => {
    return (
      <div className={styles.textContent_container}>
        <pre>{content}</pre>
      </div>
    );
  };

  const renderMarkdownContent = (content: string) => {
    return <MarkdownViewer>{content}</MarkdownViewer>;
  };

  const renderCodeContent = (content: string, codeLanguage: SupportedViewerLanguage) => {
    return (
      <div>
        <React.Suspense fallback={<LoadingIndicator />}>
          <CodeViewer code={content} codeLanguage={codeLanguage} />
        </React.Suspense>
      </div>
    );
  };

  // TODO: Create a component for displaying file content + with highlight logic https://github.com/deepset-ai/haystack-hub-ui/issues/2438
  const renderFile = () => {
    if (!selectedFileID) return null;
    if (loadingFileContent || !filePreview) return <LoadingIndicator />;

    const { content, displayLanguage } = filePreview;
    const isArrayBufferType = content instanceof ArrayBuffer;

    if (isArrayBufferType) return renderPDFContent;

    if (displayLanguage === SupportedViewerLanguage.PLAIN_TEXT) return renderTextContent(content);

    if (displayLanguage === SupportedViewerLanguage.MARKDOWN && !showRawCodeFormat)
      return renderMarkdownContent(content);

    return renderCodeContent(content, displayLanguage || SupportedViewerLanguage.PLAIN_TEXT);
  };

  const renderFileDocument = (fileDocuments: ISearchResultDocument[]) => {
    if (!fileDocuments.length) return null;

    return fileDocuments.map(({ content, id: documentId, meta }, idx) => (
      <div key={documentId} className={styles.fileDocument_container}>
        <div>
          {/* TODO: Create a container component for document content, and reuse it in the document preview drawer https://github.com/deepset-ai/haystack-hub-ui/issues/2437 */}
          {renderTextContent(content)}
          <Button
            size="small"
            className={styles.viewMetadata_button}
            onClick={() => {
              setIsMetaModalVisible(true);
              setCurrentModalMetaData(meta);
            }}
          >
            {VIEW_METADATA_BUTTON_LABEL}
          </Button>
        </div>
        {idx !== fileDocuments.length - 1 && <Divider />}
      </div>
    ));
  };

  return (
    <div className={styles.container}>
      {renderMetaDataModal()}
      <div className={styles.container_header}>
        <h5>{SOURCES_FILE_LABEL}</h5>
      </div>
      <Collapse
        className={styles.filesCollapse_container}
        accordion
        activeKey={selectedFileID}
        onChange={(key) => onFileCollapseChange(key as string[])}
        expandIconPosition="end"
      >
        {Object.keys(documentsGroupedByFileId).map((id) => (
          <Panel
            header={
              <div className={styles.filesCollapse_container_panel_title}>
                <FileTextFilled />
                <Tooltip title={getGroupedDocumentsFileName(id)}>
                  {getGroupedDocumentsFileName(id)}
                </Tooltip>
              </div>
            }
            key={id}
          >
            <div className={styles.filesCollapse_container_panel_header}>
              <Tabs
                activeKey={currentActiveCollapseTab}
                items={FILE_DRAWER_COLLAPSE_TABS}
                onChange={(activeKey) => {
                  setCurrentActiveCollapseTab(activeKey);
                  setSelectedFileID(id);
                  if (onTabChange) onTabChange({ tab: activeKey, fileId: id });
                }}
              />
              {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE && (
                <div className={styles.buttonsWrapper}>
                  {showRawCodeFormatSwitch() && (
                    <SwitchWithText
                      text={interpolateString(RAW_FORMAT_LABEL, {
                        format: filePreview?.displayLanguage || '',
                      })}
                      value={showRawCodeFormat}
                      onSwitchChange={setShowRawCodeFormat}
                    />
                  )}
                  <Button
                    size="small"
                    className={styles.viewMetadata_button}
                    onClick={() => {
                      setIsMetaModalVisible(true);
                    }}
                  >
                    {VIEW_METADATA_BUTTON_LABEL}
                  </Button>
                </div>
              )}
            </div>
            {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE ? (
              <div className={styles.file_container}>{renderFile()}</div>
            ) : (
              renderFileDocument(documentsGroupedByFileId[id])
            )}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default SourcesListCollapsePreview;
