import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputRef } from 'antd';
import { pipelineInProgress } from '@utils/pipelines';
import { isValidDataName } from '@utils/string';
import usePipelineActions from '@hooks/usePipelineActions';
import { useUserEvent } from '@hooks/useUserEvent';
import { MessageCodes } from '@constants/enum/common';
import { EventControlComponent, UserEventType } from '@constants/event';
import { ATTEMPT_READ_ONLY_EDIT } from '@constants/pipeline-designer';
import {
  setIndexingPipelineEditedStateTo as setIndexingPipelineEditedStateToAction,
  setQueryPipelineEditedStateTo as setQueryPipelineEditedStateToAction,
} from '@redux/actions/pipelineActions';
import {
  DeepsetCloudVersion,
  EditorYamlTabsKeys,
  IMessage,
  PipelineServiceLevel,
} from '@redux/types/types';
import useCodeEditor from './useCodeEditor';

// TODO: Split this hook properly and probably think of renaming it
// This hook is a hook for the pipeline editor page
// useCodeEditor is a hook for the code editor component (yaml editor)

const usePipelineEditor = () => {
  const dispatch = useDispatch();

  const {
    pipeline,
    renamePipelineNameStatus,
    fetchPipelineYamlStatus,
    pipelineCreateUpdatePipelineStatus,
    pipelineIndexingData,
    pipelineErrors,
    resetPipelineErrors,
    getRuntimeIssueDetection,
    validatePipelineYaml,
    setPipelineYamlV2,
    updatePipeline,
    resetMessage,
    updatePipelineYaml,
    createPipeline,
  } = usePipelineActions();

  const {
    activeTabYamlEditor,
    indexingCode,
    queryCode,
    indexingYamlEditorDiff,
    queryYamlEditorDiff,
    setIndexingCode,
    setQueryCode,
    setIndexingYamlEditorDiff,
    setQueryYamlEditorDiff,
  } = useCodeEditor();

  const {
    name,
    status,
    editedIndexingYaml,
    editedQueryYaml,
    indexing,
    yaml,
    indexing_yaml: indexingYaml,
    query_yaml: queryYaml,
    service_level: serviceLevel,
  } = pipeline;

  const [loadingButton, setLoadingButton] = useState(false);
  const [savingPipeline, setSavingPipeline] = useState(false);
  const [editPipelineName, setEditPipelineName] = useState(false);
  const [openPipelineConfirmationModal, setOpenPipelineConfirmationModal] = useState(false);
  const [selectedYaml, setSelectedYaml] = useState<string>(indexingYaml || '');
  const [newPipelineName, setNewPipelineName] = useState('');
  const [successfulValidationPipelineName, setSuccessfulValidationPipelineName] =
    useState<boolean>(true);
  const [warningMessage, setWarningMessage] = useState<IMessage>({
    type: MessageCodes.WARNING,
    content: '',
  });
  const pipelineNameInputRef = useRef<InputRef>(null);
  const editPipelineNameButtonRef = useRef<HTMLButtonElement>(null);

  const { trackUserEvent } = useUserEvent();

  const { pending_file_count: pipelinePendingFileCount } = indexing;

  const getPipelineIssues = async (pipelineName: string) => {
    await resetPipelineErrors();
    getRuntimeIssueDetection(pipelineName);
    validatePipelineYaml({
      deepsetCloudVersion: pipeline.deepset_cloud_version,
      indexingYaml: indexingYaml || '',
      queryYaml: queryYaml || '',
    });
  };

  const setIndexingPipelineEditedStateTo = (edited: boolean) => {
    dispatch(setIndexingPipelineEditedStateToAction(edited));
  };

  const setQueryPipelineEditedStateTo = (edited: boolean) => {
    dispatch(setQueryPipelineEditedStateToAction(edited));
  };

  const onRenamePipeline = () => {
    if (name && newPipelineName && newPipelineName !== name && isValidDataName(newPipelineName)) {
      const payload = {
        pipelineName: name,
        newPipelineName,
      };

      updatePipeline(payload);
    }
  };

  const sendMetricsDcStudio = (userEventType: UserEventType, eventControlElement: string) => {
    trackUserEvent({
      type: userEventType,
      control: `${EventControlComponent.STUDIO}/${eventControlElement}`,
      properties: {
        version: 'dc',
      },
    });
  };

  const onSavePipeline = async (
    pipelineName: string,
    indexingYamlCode?: string,
    queryYamlCode?: string,
  ) => {
    if (!pipelineName && !name) {
      setSuccessfulValidationPipelineName(false);
      setEditPipelineName(true);
    } else {
      setSavingPipeline(true);
      resetMessage();

      if (indexingYamlCode) setIndexingCode(indexingYamlCode);
      if (queryYamlCode) setQueryCode(queryYamlCode);

      if ((pipelineName || name) && pipeline.pipeline_id) {
        await updatePipelineYaml({
          indexing_yaml: indexingYamlCode ?? indexingCode,
          query_yaml: queryYamlCode ?? queryCode,
          name,
        });
      } else if (!pipeline.pipeline_id) {
        const payload = {
          pipelineName: name,
          deepsetCloudVersion: DeepsetCloudVersion.V2,
          indexingYaml: indexingYamlCode || indexingCode,
          queryYaml: queryYamlCode || queryCode,
        };

        createPipeline(payload);
      }
    }
  };

  const resetCode = (pipelineName: string) => {
    if (indexingYaml !== null && activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML) {
      setIndexingCode(indexingYaml);
      setIndexingYamlEditorDiff(false);
      setIndexingPipelineEditedStateTo(false);
    } else if (queryYaml !== null && activeTabYamlEditor === EditorYamlTabsKeys.QUERY_YAML) {
      setQueryCode(queryYaml);
      setQueryYamlEditorDiff(false);
      setQueryPipelineEditedStateTo(false);
    }

    setPipelineYamlV2({ indexingYaml: indexingYaml || '', queryYaml: queryYaml || '' });
    getPipelineIssues(pipelineName);
  };

  const onDidAttemptReadOnlyEditCode = () => {
    if (serviceLevel === PipelineServiceLevel.PRODUCTION)
      setWarningMessage({
        content: ATTEMPT_READ_ONLY_EDIT.productionPipelines,
        type: MessageCodes.WARNING,
      });
  };

  const isDesignerReadOnly = () => {
    if (serviceLevel === PipelineServiceLevel.PRODUCTION) return true;
    if (pipelineInProgress(status)) return true;
    return false;
  };

  const storeCode = (newValue: string) => {
    if (indexingYaml !== null && activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML) {
      setIndexingCode(newValue);
      setIndexingPipelineEditedStateTo(true);
    } else if (queryYaml !== null && activeTabYamlEditor === EditorYamlTabsKeys.QUERY_YAML) {
      setQueryCode(newValue);
      setQueryPipelineEditedStateTo(true);
    }
  };

  const getEditorCode = () => {
    if (indexingYaml !== null && activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML)
      return indexingCode;
    if (queryYaml !== null && activeTabYamlEditor === EditorYamlTabsKeys.QUERY_YAML)
      return queryCode;
    return '';
  };

  const getOriginalCode = () => {
    if (activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML) return indexingYamlEditorDiff;
    return queryYamlEditorDiff;
  };

  const getEditedYaml = () => {
    if (editedIndexingYaml && activeTabYamlEditor === EditorYamlTabsKeys.INDEXING_YAML)
      return editedIndexingYaml;
    if (editedQueryYaml && activeTabYamlEditor === EditorYamlTabsKeys.QUERY_YAML)
      return editedQueryYaml;
    return false;
  };

  return {
    pipeline,
    name,
    status,
    editedIndexingYaml,
    editedQueryYaml,
    yaml,
    indexingYaml,
    queryYaml,
    serviceLevel,
    renamePipelineNameStatus,
    fetchPipelineYamlStatus,
    pipelineCreateUpdatePipelineStatus,
    pipelineIndexingData,
    loadingButton,
    savingPipeline,
    editPipelineName,
    openPipelineConfirmationModal,
    successfulValidationPipelineName,
    pipelineNameInputRef,
    editPipelineNameButtonRef,
    newPipelineName,
    pipelinePendingFileCount,
    activeTabYamlEditor,
    warningMessage,
    pipelineErrors,
    selectedYaml,
    getEditedYaml,
    setLoadingButton,
    setSavingPipeline,
    setEditPipelineName,
    setOpenPipelineConfirmationModal,
    setSuccessfulValidationPipelineName,
    setNewPipelineName,
    getPipelineIssues,
    resetCode,
    onRenamePipeline,
    onSavePipeline,
    onDidAttemptReadOnlyEditCode,
    isDesignerReadOnly,
    storeCode,
    setSelectedYaml,
    getEditorCode,
    getOriginalCode,
    sendMetricsDcStudio,
  };
};

export default usePipelineEditor;
