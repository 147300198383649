import { ChartExportType } from '@redux/types/types';

export const EXPORT_CHART_OPTIONS = [
  {
    label: 'Export as PNG',
    key: ChartExportType.PNG,
  },
  {
    label: 'Export as JPEG',
    key: ChartExportType.JPEG,
  },
  {
    label: 'Export as PDF',
    key: ChartExportType.PDF,
  },
];
