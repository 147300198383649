import React from 'react';
import ChartRenderer from './components/ChartRenderer';
import { ArtifactMetadata, ArtifactType, BaseArtifact, ChartArtifactData } from './types/artifacts';

export class ChartArtifact implements BaseArtifact<ChartArtifactData> {
  metadata: ArtifactMetadata;

  data: ChartArtifactData;

  constructor(data: ChartArtifactData, metadata: Partial<ArtifactMetadata>) {
    this.data = data;
    this.metadata = {
      type: ArtifactType.CHART,
      source: {
        prompt: metadata.source?.prompt,
      },
      ...metadata,
    };
  }

  render() {
    return <ChartRenderer chartData={this.data} />;
  }
}
