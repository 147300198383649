import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import userflow, { Attributes, IdentifyOptions } from 'userflow.js';
import { LOAD_ERROR_MESSAGE } from '../constants/userflow';
import UserflowContext, { IUserflowContext } from '../context/userflowContext';
import { useRetry } from '../hooks/useRetry';
import { ActionType, reducer } from '../reducer/reducer';
import { initialUserflowState } from '../state/userflowState';

export interface IUserflowProviderProps {
  token?: string;
  children: React.ReactNode;
  context?: React.Context<any>;
}

const UserflowProvider = ({
  token,
  children,
  context = UserflowContext,
}: IUserflowProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialUserflowState);
  const componentDidMount = useRef(false);
  const { executeWithRetry } = useRetry();

  const initUserflow = useCallback(async () => {
    if (!token || state.isInitialized) return;

    const loadUserflow = async () => {
      await userflow.load();
      userflow.init(token);
      userflow.setResourceCenterLauncherHidden(true);
      dispatch({ type: ActionType.INITIALISED });
    };

    try {
      await executeWithRetry(
        loadUserflow,
        (error) => error instanceof Error && error.message === LOAD_ERROR_MESSAGE,
      );
    } catch (error) {
      if (error instanceof Error && error.message === LOAD_ERROR_MESSAGE) return;
      console.error(error);
    }
  }, [token, state.isInitialized]);

  useEffect(() => {
    if (componentDidMount.current) return;
    componentDidMount.current = true;
    initUserflow();
  }, [token]);

  const identify = useCallback(
    (userId: string, attributes?: Attributes, opts?: IdentifyOptions) => {
      if (!token || !state.isInitialized) return;
      if (userflow.isIdentified()) return;

      userflow.identify(userId, attributes, opts);
    },
    [token, state.isInitialized],
  );

  const contextValue = useMemo<IUserflowContext>(() => {
    return {
      ...state,
      identify,
      init: initUserflow,
    };
  }, [state, identify, initUserflow]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default UserflowProvider;
