import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Edge, Node, ReactFlowInstance } from '@xyflow/react';
import yaml from 'js-yaml';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import {
  generateIntegratedPipelineYamlApi,
  getPipelineSchemaV2Api,
  getPipelineSchemaV2InputOutputApi,
} from '@api/pipeline';
import { StatusCodes } from '@constants/enum/common';
import { fetchPipelineYaml } from '@redux/actions/pipelineActions';
import { RootState } from '@redux/store';
import { StudioYamlTabsKeys } from '../constants/pipeline-studio';
import { getYamlString } from '../logic/generateYaml';
import { getComponentMappings, getInputOutputMappings } from '../logic/transformSchema';
import { IComponentMapping } from '../logic/types';

interface StudioState {
  pipelineSchema: Record<string, any> | null;
  haystackTypes: Record<string, any> | {};
  componentMap: IComponentMapping[];
  fetchPipelineSchemaStatus: StatusCodes;
  inputOutputMap: Record<string, any>;
  fetchPipelineInputOutputsStatus: StatusCodes;
  activeTab: StudioYamlTabsKeys;
  isYamlView: boolean;
  downloadYamlModalOpen: boolean;
  indexingYaml: string;
  queryYaml: string;
  fetchedSingleIntegratedPipelineYaml: StatusCodes;
  fetchedIntegratedPipelinesYaml: StatusCodes;
  indexingNodes: Node[];
  queryNodes: Node[];
  indexingEdges: Edge[];
  queryEdges: Edge[];
  isIndexingGraphValid: boolean;
  isQueryGraphValid: boolean;
  isReadOnly: boolean;
  hoveredNodeId: string;
  reactFlowInstance: ReactFlowInstance | null;
  newNodeAdded: boolean;
}

export const initialState: StudioState = {
  pipelineSchema: {},
  haystackTypes: {},
  componentMap: [],
  fetchPipelineSchemaStatus: StatusCodes.IDLE,
  inputOutputMap: {},
  fetchPipelineInputOutputsStatus: StatusCodes.IDLE,
  activeTab: StudioYamlTabsKeys.INDEXING_YAML,
  isYamlView: false,
  downloadYamlModalOpen: false,
  indexingYaml: '',
  queryYaml: '',
  fetchedSingleIntegratedPipelineYaml: StatusCodes.IDLE,
  fetchedIntegratedPipelinesYaml: StatusCodes.IDLE,
  indexingNodes: [],
  queryNodes: [],
  indexingEdges: [],
  queryEdges: [],
  isIndexingGraphValid: true,
  isQueryGraphValid: true,
  isReadOnly: false,
  hoveredNodeId: '',
  reactFlowInstance: null,
  newNodeAdded: false,
};

export const fetchPipelineSchema = createAsyncThunk('users/fetchPipelineSchema', async () => {
  const response = await getPipelineSchemaV2Api();
  return response.data.component_schema;
});

export const fetchPipelineInputOutputs = createAsyncThunk(
  'users/fetchPipelineInputOutputs',
  async () => {
    const response = await getPipelineSchemaV2InputOutputApi();
    return response.data;
  },
);

export const generateIntegratedPipelineYaml = createAsyncThunk(
  'users/generateIntegratedPipelineYaml',
  async (payload: { original: string; updated: Record<string, unknown> }) => {
    const response = await generateIntegratedPipelineYamlApi(payload);
    return response.data;
  },
);

export const generateIntegratedIndexingAndQueryPipelineYaml = createAsyncThunk(
  'users/generateIntegratedIndexingAndQueryPipelineYaml',
  async (payload: {
    indexing: { original: string; updated: Record<string, unknown> };
    query: { original: string; updated: Record<string, unknown> };
    indexingPipelineCanBeEdited: boolean;
  }) => {
    const response = {
      indexingYaml: '',
      queryYaml: '',
    };
    const indexingResponse = payload.indexingPipelineCanBeEdited
      ? (await generateIntegratedPipelineYamlApi(payload.indexing)).data
      : payload.indexing.original;
    response.indexingYaml = indexingResponse;
    const queryResponse = await generateIntegratedPipelineYamlApi(payload.query);
    response.queryYaml = queryResponse.data;
    return response;
  },
);

export const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    resetStudioState: (state) => {
      const { pipelineSchema, haystackTypes, componentMap, inputOutputMap } = state;
      Object.assign(state, {
        ...initialState,
        pipelineSchema,
        haystackTypes,
        componentMap,
        inputOutputMap,
      });
    },
    setActiveTab: (state, action: PayloadAction<StudioYamlTabsKeys>) => {
      state.activeTab = action.payload;
    },
    setIsYamlView: (state, action: PayloadAction<boolean>) => {
      state.isYamlView = action.payload;
    },
    setDownloadYamlModalOpen: (state, action: PayloadAction<boolean>) => {
      state.downloadYamlModalOpen = action.payload;
    },
    setIsIndexingGraphValid: (state, action: PayloadAction<boolean>) => {
      state.isIndexingGraphValid = action.payload;
    },
    setIsQueryGraphValid: (state, action: PayloadAction<boolean>) => {
      state.isQueryGraphValid = action.payload;
    },
    setIndexingYaml: (state, action: PayloadAction<string>) => {
      try {
        yaml.load(action.payload);
        state.isIndexingGraphValid = true;
      } catch (e) {
        state.isIndexingGraphValid = false;
      }
      state.indexingYaml = action.payload;
    },
    setQueryYaml: (state, action: PayloadAction<string>) => {
      try {
        yaml.load(action.payload);
        state.isQueryGraphValid = true;
      } catch (e) {
        state.isQueryGraphValid = false;
      }
      state.queryYaml = action.payload;
    },
    setComponentMap: (state, action: PayloadAction<IComponentMapping[]>) => {
      state.componentMap = action.payload;
    },
    setNodesInputOutput: (state, action: PayloadAction<Record<string, any>>) => {
      state.inputOutputMap = action.payload;
    },
    updateInputOutputMap: (state, action: PayloadAction<Record<string, any>>) => {
      const hasNewValues = Object.keys(action.payload).some(
        (key) => !isEqual(state.inputOutputMap[key], action.payload[key]),
      );

      if (hasNewValues) {
        state.inputOutputMap = {
          ...state.inputOutputMap,
          ...action.payload,
        };
      }
    },
    setIndexingNodes: (state, action: PayloadAction<Node[]>) => {
      // Why are we using cloneDeep here?
      // This is why: When using Redux to store nodes and edges data for React Flow,
      // issues can arise due to the need for immutability and the frequent updates required
      // for these complex structures.

      const hasChanged = !isEqual(action.payload, state.indexingNodes);
      if (hasChanged) {
        const updatedNodes = cloneDeep(action.payload);
        state.indexingNodes = updatedNodes;
      }
    },
    setQueryNodes: (state, action: PayloadAction<Node[]>) => {
      const hasChanged = !isEqual(action.payload, state.queryNodes);
      if (hasChanged) {
        const updatedNodes = cloneDeep(action.payload);
        state.queryNodes = updatedNodes;
      }
    },
    setIndexingEdges: (state, action: PayloadAction<Edge[]>) => {
      const hasChanged = !isEqual(action.payload, state.indexingEdges);
      if (hasChanged) {
        const updatedEdges = cloneDeep(action.payload);
        state.indexingEdges = updatedEdges;
      }
    },
    setQueryEdges: (state, action: PayloadAction<Edge[]>) => {
      const hasChanged = !isEqual(action.payload, state.queryEdges);
      if (hasChanged) {
        const updatedEdges = cloneDeep(action.payload);
        state.queryEdges = updatedEdges;
      }
    },
    setIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload;
    },
    setHoveredNodeId: (state, action: PayloadAction<string>) => {
      state.hoveredNodeId = action.payload;
    },
    setReactFlowInstance: (state, action: PayloadAction<ReactFlowInstance>) => {
      state.reactFlowInstance = action.payload;
    },
    setNewNodeAdded: (state, action: PayloadAction<boolean>) => {
      state.newNodeAdded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPipelineSchema.pending, (state) => {
      state.fetchPipelineSchemaStatus = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(fetchPipelineSchema.fulfilled, (state, action) => {
      state.fetchPipelineSchemaStatus = StatusCodes.SUCCESS;

      if (action.payload) {
        const components = action.payload.definitions?.Components || {};
        state.haystackTypes = action.payload.definitions?.haystackTypes || {};
        state.pipelineSchema = components;
        state.componentMap = getComponentMappings(components);
      }
    });
    builder.addCase(fetchPipelineSchema.rejected, (state) => {
      state.fetchPipelineSchemaStatus = StatusCodes.ERROR;
    });

    builder.addCase(fetchPipelineInputOutputs.pending, (state) => {
      state.fetchPipelineInputOutputsStatus = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(fetchPipelineInputOutputs.fulfilled, (state, action) => {
      state.fetchPipelineInputOutputsStatus = StatusCodes.SUCCESS;
      if (action.payload) {
        state.inputOutputMap = getInputOutputMappings(action.payload);
      }
    });
    builder.addCase(fetchPipelineInputOutputs.rejected, (state) => {
      state.fetchPipelineInputOutputsStatus = StatusCodes.ERROR;
    });

    builder.addCase(fetchPipelineYaml.fulfilled, (state, action) => {
      state.indexingYaml = action.payload.indexing_yaml;
      state.queryYaml = action.payload.query_yaml;
    });
    builder.addCase(generateIntegratedPipelineYaml.pending, (state) => {
      state.fetchedSingleIntegratedPipelineYaml = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(generateIntegratedPipelineYaml.fulfilled, (state, action) => {
      if (state.activeTab === StudioYamlTabsKeys.INDEXING_YAML) {
        try {
          yaml.load(action.payload);
          state.isIndexingGraphValid = true;
        } catch (e) {
          state.isIndexingGraphValid = false;
        }
        state.indexingYaml = action.payload;
      } else if (state.activeTab === StudioYamlTabsKeys.QUERY_YAML) {
        try {
          yaml.load(action.payload);
          state.isQueryGraphValid = true;
        } catch (e) {
          state.isQueryGraphValid = false;
        }
        state.queryYaml = action.payload;
      }
      state.fetchedSingleIntegratedPipelineYaml = StatusCodes.SUCCESS;
    });
    builder.addCase(generateIntegratedPipelineYaml.rejected, (state, action) => {
      const yamlString = !isEmpty(action.meta.arg.updated)
        ? getYamlString(action.meta.arg.updated)
        : action.meta.arg.original;
      if (state.activeTab === StudioYamlTabsKeys.INDEXING_YAML) state.indexingYaml = yamlString;
      else if (state.activeTab === StudioYamlTabsKeys.QUERY_YAML) state.queryYaml = yamlString;
      state.fetchedSingleIntegratedPipelineYaml = StatusCodes.ERROR;
    });
    builder.addCase(generateIntegratedIndexingAndQueryPipelineYaml.pending, (state) => {
      state.fetchedIntegratedPipelinesYaml = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(generateIntegratedIndexingAndQueryPipelineYaml.fulfilled, (state, action) => {
      state.indexingYaml = action.payload.indexingYaml;
      state.queryYaml = action.payload.queryYaml;
      state.fetchedIntegratedPipelinesYaml = StatusCodes.SUCCESS;
    });
    builder.addCase(generateIntegratedIndexingAndQueryPipelineYaml.rejected, (state) => {
      state.fetchedIntegratedPipelinesYaml = StatusCodes.ERROR;
    });
  },
});

export const {
  resetStudioState,
  setActiveTab,
  setIsYamlView,
  setIndexingYaml,
  setQueryYaml,
  setNodesInputOutput,
  setComponentMap,
  updateInputOutputMap,
  setIndexingNodes,
  setQueryNodes,
  setIndexingEdges,
  setQueryEdges,
  setDownloadYamlModalOpen,
  setIsIndexingGraphValid,
  setIsQueryGraphValid,
  setIsReadOnly,
  setHoveredNodeId,
  setReactFlowInstance,
  setNewNodeAdded,
} = studioSlice.actions;

export default studioSlice.reducer;

// SELECTORS
const selectStudioState = (state: RootState) => state.studioStore;

const getPipelineSchema = (state: RootState) => selectStudioState(state).pipelineSchema;
const getHaystackTypes = (state: RootState) => selectStudioState(state).haystackTypes;
const getComponentMap = (state: RootState) => selectStudioState(state).componentMap;
const getFetchPipelineSchemaStatus = (state: RootState) =>
  selectStudioState(state).fetchPipelineSchemaStatus;
const getFetchPipelineInputOutputsStatus = (state: RootState) =>
  selectStudioState(state).fetchPipelineInputOutputsStatus;
const getInputOutputMap = (state: RootState) => selectStudioState(state).inputOutputMap;
const getActiveTab = (state: RootState) => selectStudioState(state).activeTab;
const getIsYamlView = (state: RootState) => selectStudioState(state).isYamlView;
const getDownloadYamlModalOpen = (state: RootState) =>
  selectStudioState(state).downloadYamlModalOpen;
const getIndexingYaml = (state: RootState) => selectStudioState(state).indexingYaml;
const getQueryYaml = (state: RootState) => selectStudioState(state).queryYaml;
const getIndexingNodes = (state: RootState) => selectStudioState(state).indexingNodes;
const getQueryNodes = (state: RootState) => selectStudioState(state).queryNodes;
const getIndexingEdges = (state: RootState) => selectStudioState(state).indexingEdges;
const getQueryEdges = (state: RootState) => selectStudioState(state).queryEdges;
const getFetchedSingleIntegratedPipelineYaml = (state: RootState) =>
  selectStudioState(state).fetchedSingleIntegratedPipelineYaml;
const getFetchedIntegratedPipelinesYaml = (state: RootState) =>
  selectStudioState(state).fetchedIntegratedPipelinesYaml;
const getIsIndexingGraphValid = (state: RootState) => selectStudioState(state).isIndexingGraphValid;
const getIsQueryGraphValid = (state: RootState) => selectStudioState(state).isQueryGraphValid;
const getIsReadOnly = (state: RootState) => selectStudioState(state).isReadOnly;
const getHoveredNodeId = (state: RootState) => selectStudioState(state).hoveredNodeId;
const getReactFlowInstance = (state: RootState) => selectStudioState(state).reactFlowInstance;
const getNewNodeAdded = (state: RootState) => selectStudioState(state).newNodeAdded;

// createSelector
export const selectPipelineSchema = createSelector(
  [getPipelineSchema],
  (pipelineSchema) => pipelineSchema,
);
export const selectHaystackTypes = createSelector(
  [getHaystackTypes],
  (haystackTypes) => haystackTypes,
);
export const selectComponentMap = createSelector([getComponentMap], (componentMap) => componentMap);
export const selectInputOutputMap = createSelector(
  [getInputOutputMap],
  (inputOutputMap) => inputOutputMap,
);
export const selectFetchPipelineSchemaStatus = createSelector(
  [getFetchPipelineSchemaStatus],
  (fetchPipelineSchemaStatus) => fetchPipelineSchemaStatus,
);
export const selectFetchPipelineInputOutputsStatus = createSelector(
  [getFetchPipelineInputOutputsStatus],
  (fetchPipelineInputOutputsStatus) => fetchPipelineInputOutputsStatus,
);
export const selectActiveTab = createSelector([getActiveTab], (activeTab) => activeTab);
export const selectIsYamlView = createSelector([getIsYamlView], (isYamlView) => isYamlView);
export const selectDownloadYamlModalOpen = createSelector(
  [getDownloadYamlModalOpen],
  (downloadYamlModalOpen) => downloadYamlModalOpen,
);
export const selectIndexingYaml = createSelector([getIndexingYaml], (indexingYaml) => indexingYaml);
export const selectQueryYaml = createSelector([getQueryYaml], (queryYaml) => queryYaml);
export const selectIndexingNodes = createSelector(
  [getIndexingNodes],
  (indexingNodes) => indexingNodes,
);
export const selectQueryNodes = createSelector([getQueryNodes], (queryNodes) => queryNodes);
export const selectIndexingEdges = createSelector(
  [getIndexingEdges],
  (indexingEdges) => indexingEdges,
);
export const selectQueryEdges = createSelector([getQueryEdges], (queryEdges) => queryEdges);
export const selectFetchedSingleIntegratedPipelineYaml = createSelector(
  [getFetchedSingleIntegratedPipelineYaml],
  (fetchedSingleIntegratedPipelineYaml) => fetchedSingleIntegratedPipelineYaml,
);
export const selectFetchedIntegratedPipelinesYaml = createSelector(
  [getFetchedIntegratedPipelinesYaml],
  (fetchedIntegratedPipelinesYaml) => fetchedIntegratedPipelinesYaml,
);
export const selectIsIndexingGraphValid = createSelector(
  [getIsIndexingGraphValid],
  (isIndexingGraphValid) => isIndexingGraphValid,
);
export const selectIsQueryGraphValid = createSelector(
  [getIsQueryGraphValid],
  (isQueryGraphValid) => isQueryGraphValid,
);
export const selectIsReadOnly = createSelector([getIsReadOnly], (isReadOnly) => isReadOnly);
export const selectHoveredNodeId = createSelector(
  [getHoveredNodeId],
  (hoveredNodeId) => hoveredNodeId,
);
export const selectReactFlowInstance = createSelector(
  [getReactFlowInstance],
  (reactFlowInstance) => reactFlowInstance,
);
export const selectNewNodeAdded = createSelector([getNewNodeAdded], (newNodeAdded) => newNodeAdded);
