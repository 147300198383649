import { useCallback, useEffect, useRef } from 'react';
import { USERFLOW_MAX_RETRIES, USERFLOW_RETRY_DELAY } from '../constants/userflow';

interface UseRetryConfig {
  maxRetries?: number;
  retryDelay?: number;
}

export const useRetry = ({
  maxRetries = USERFLOW_MAX_RETRIES,
  retryDelay = USERFLOW_RETRY_DELAY,
}: UseRetryConfig = {}) => {
  const retryCount = useRef(0);
  const retryTimeoutRef = useRef<NodeJS.Timeout>();

  const resetRetryCount = useCallback(() => {
    retryCount.current = 0;
  }, []);

  const executeWithRetry = useCallback(
    async <T>(
      operation: () => Promise<T>,
      shouldRetry: (error: unknown) => boolean,
    ): Promise<T> => {
      try {
        const result = await operation();
        resetRetryCount();
        return result;
      } catch (error) {
        if (shouldRetry(error) && retryCount.current < maxRetries) {
          retryCount.current += 1;

          if (retryTimeoutRef.current) {
            clearTimeout(retryTimeoutRef.current);
          }

          return new Promise((resolve, reject) => {
            retryTimeoutRef.current = setTimeout(async () => {
              try {
                const result = await executeWithRetry(operation, shouldRetry);
                resolve(result);
              } catch (retryError) {
                reject(retryError);
              }
            }, retryDelay);
          });
        }
        throw error;
      }
    },
    [maxRetries, retryDelay, resetRetryCount],
  );

  useEffect(() => {
    return () => {
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, []);

  return {
    executeWithRetry,
    resetRetryCount,
    retryCount: retryCount.current,
  };
};
