import React, { useEffect, useMemo, useRef } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import {
  BarChart,
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  PieChart,
  PieSeriesOption,
  RadarChart,
  RadarSeriesOption,
} from 'echarts/charts';
import {
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { pngToPDF } from '@utils/file';
import { ChartExportType } from '@redux/types/types';
import defaultTheme from '@styles/themes/default';
import styles from './chartRenderer.module.scss';
import { EXPORT_CHART_OPTIONS } from '../constants/chart';
import { ChartArtifactData } from '../types/artifacts';
import { mapArtifactDataToEChartsOption } from '../utils/chart';

echarts.use([
  CanvasRenderer,
  BarChart,
  LineChart,
  PieChart,
  RadarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  UniversalTransition,
]);

export type EChartsOption = echarts.ComposeOption<
  | GridComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | DataZoomComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | PieSeriesOption
  | RadarSeriesOption
>;

interface IChartRendererProps {
  chartData: ChartArtifactData;
}

const ChartRenderer: React.FC<IChartRendererProps> = ({ chartData }) => {
  const chartDivRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<echarts.ECharts>();

  const options = useMemo(() => mapArtifactDataToEChartsOption(chartData), [chartData]);

  const handleResize = () => {
    chartRef?.current?.resize();
  };

  const exportChart = (type: ChartExportType) => {
    if (!chartRef.current) return;

    const dataUrlType = type === ChartExportType.PDF ? ChartExportType.PNG : type;
    const fileName = `${chartData.title.toLowerCase().replace(/\s+/g, '-')}.${type}`;

    const dataURL = chartRef.current.getDataURL({
      type: dataUrlType as Exclude<ChartExportType, 'csv' | 'pdf'>,
      pixelRatio: 4,
      backgroundColor: defaultTheme!.token!.colorWhite!,
      excludeComponents: ['dataZoom'],
    });

    if (type === ChartExportType.PDF) {
      const canvas = chartRef.current.getDom();
      if (!canvas) return;
      const { clientWidth, clientHeight } = canvas;
      pngToPDF(dataURL, fileName, {
        width: clientWidth,
        height: clientHeight,
      });
      return;
    }

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    if (chartDivRef.current) {
      chartRef.current = echarts.init(chartDivRef.current);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.setOption(options);
    }
  }, [options]);

  return (
    <div className={styles.container}>
      <div className={styles.optionsContainer}>
        <Dropdown
          menu={{
            items: EXPORT_CHART_OPTIONS,
            onClick: ({ key }) => exportChart(key as ChartExportType),
          }}
          placement="bottomRight"
        >
          <Button type="text" icon={<MoreOutlined />} />
        </Dropdown>
      </div>
      <div ref={chartDivRef} className={styles.chart} />
    </div>
  );
};

export default ChartRenderer;
