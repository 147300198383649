import { UploadFileType } from '@constants/constant';
import { getWorkspace } from '@src/utils/store';
import { httpExt } from './base-http-external';

/* Get File as blob for external users */
export const getFileBlobApi = (fileId: string, workspace: string = getWorkspace()) => {
  return httpExt.get(`/api/v1/workspaces/${workspace}/files/${fileId}`, { responseType: 'blob' });
};

export const getFilePDFConversionBlobApi = (fileId: string, workspace: string = getWorkspace()) => {
  return httpExt.get(`/api/v1/workspaces/${workspace}/files/${fileId}/convert-to-pdf`, {
    responseType: 'blob',
  });
};

export const getFilePreviewBlobByTypeApi = (
  fileId: string,
  fileType: UploadFileType | null,
  workspace: string = getWorkspace(),
) => {
  const isDisabledFileConversion = true; // Todo: Enable once api ready
  const fileTypeForPDFConversion = [
    UploadFileType.docx,
    UploadFileType.doc,
    UploadFileType.xlsx,
    UploadFileType.xls,
  ];

  if (!isDisabledFileConversion && fileType && fileTypeForPDFConversion.includes(fileType))
    return getFilePDFConversionBlobApi(fileId, workspace);

  return getFileBlobApi(fileId, workspace);
};

export const getFileMetaApi = (file_id: string, workspace: string = getWorkspace()) => {
  return httpExt.get(`/api/v1/workspaces/${workspace}/files/${file_id}/meta`);
};
