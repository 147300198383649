import { ChartArtifact } from './ChartArtifact';
import { ArtifactMetadata, ArtifactType, BaseArtifact, ChartArtifactData } from './types/artifacts';

export class ArtifactFactory {
  static create<T>(
    type: ArtifactType,
    data: T,
    metadata: Partial<ArtifactMetadata>,
  ): BaseArtifact<T> {
    if (type !== ArtifactType.CHART) {
      throw new Error('Only chart artifacts are currently supported');
    }
    return new ChartArtifact(data as ChartArtifactData, metadata) as unknown as BaseArtifact<T>;
  }
}
