import { createTransform } from 'redux-persist';
import { initialState } from './rootReducer';
import { RootState } from './store';

export const studioTransform = createTransform(
  (inboundState, key) => {
    if (key === 'studioStore') {
      const { pipelineSchema, haystackTypes, componentMap } =
        inboundState as RootState['studioStore'];
      return { pipelineSchema, haystackTypes, componentMap };
    }
    return inboundState;
  },
  (outboundState, key) => {
    if (key === 'studioStore') {
      const { pipelineSchema, haystackTypes, componentMap } =
        outboundState as RootState['studioStore'];
      return {
        ...initialState.studioStore,
        pipelineSchema,
        haystackTypes,
        componentMap,
      };
    }
    return outboundState;
  },
  { whitelist: ['studioStore'] },
);
