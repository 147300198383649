import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Menu, Tag } from 'antd';
import { CATEGORY_ALL_TEMPLATES, CATEGORY_LABELS } from '@constants/pipeline-templates';
import { selectTemplatesCategory } from '@redux/actions/pipelineTemplatesActions';
import {
  pipelineTemplatesByCategorySelector,
  selectedCategorySelector,
} from '@redux/selectors/pipelineTemplatesSelectors';
import { PipelineTemplatesCategory } from '@redux/types/types';
import styles from './categoriesSidebar.module.scss';

const ALL_TEMPLATES_KEY = 'all';

const CategoriesSidebar = () => {
  const dispatch = useDispatch();
  const templatesByCategory = useSelector(pipelineTemplatesByCategorySelector);
  const selectedCategory = useSelector(selectedCategorySelector);

  const onItemClick = (category: PipelineTemplatesCategory | null) => {
    dispatch(selectTemplatesCategory(category));
  };

  const getCategoryTotalTemplates = (category: PipelineTemplatesCategory) => {
    const { total } = templatesByCategory[category] || {};
    return total || 0;
  };

  // Renders

  const renderMenuLabel = (category: PipelineTemplatesCategory) => {
    return (
      <div className={styles.menuLabel}>
        {CATEGORY_LABELS[category]} <Tag>{getCategoryTotalTemplates(category)}</Tag>
      </div>
    );
  };

  // Menu items
  const allTemplatesMenuItem = {
    label: CATEGORY_ALL_TEMPLATES,
    key: ALL_TEMPLATES_KEY,
  };
  const categoryMenuItems = [
    {
      label: renderMenuLabel(PipelineTemplatesCategory.RECOMMENDED),
      key: PipelineTemplatesCategory.RECOMMENDED,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.DOCUMENT_SEARCH),
      key: PipelineTemplatesCategory.DOCUMENT_SEARCH,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.BASIC_QA),
      key: PipelineTemplatesCategory.BASIC_QA,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.ADVANCED_QA),
      key: PipelineTemplatesCategory.ADVANCED_QA,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.VISUAL_QA),
      key: PipelineTemplatesCategory.VISUAL_QA,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.CONVERSATIONAL),
      key: PipelineTemplatesCategory.CONVERSATIONAL,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.TEXT_ANALYSIS),
      key: PipelineTemplatesCategory.TEXT_ANALYSIS,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.TEXT_TO_SQL),
      key: PipelineTemplatesCategory.TEXT_TO_SQL,
    },
    {
      label: renderMenuLabel(PipelineTemplatesCategory.IMAGE_GENERATION),
      key: PipelineTemplatesCategory.IMAGE_GENERATION,
    },
  ];

  return (
    <div className={styles.container}>
      <Menu
        selectedKeys={[!selectedCategory ? ALL_TEMPLATES_KEY : '']}
        items={[allTemplatesMenuItem]}
        onClick={() => onItemClick(null)}
      />
      <Divider />
      <Menu
        selectedKeys={[selectedCategory || '']}
        items={categoryMenuItems}
        onClick={({ key }) => onItemClick(key as PipelineTemplatesCategory)}
      />
    </div>
  );
};

export default CategoriesSidebar;
