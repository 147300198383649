import { UploadFile } from 'antd';
import JsPDF from 'jspdf';
import { MIMETypes, SupportedViewerLanguage } from '@constants/enum/common';
import { IFilePreview } from '@redux/types/types';

export const downloadBlob = (fileName: string, blobData: Blob) => {
  const blobUrl = URL.createObjectURL(blobData);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  document.body.removeChild(link);
};

export const downloadBlobFile = (
  fileName: string,
  fileData: string,
  type: string = MIMETypes.TXT,
) => {
  const blobData = new Blob([fileData], {
    type,
  });
  downloadBlob(fileName, blobData);
};

export const openFileInNewTab = (blobData: Blob) => {
  const encodedBlob = new Blob([blobData], { type: `${blobData.type};charset=utf-8` });
  const blobUrl = URL.createObjectURL(encodedBlob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.target = '_blank';
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
};

export const cloneArrayBuffer = (buffer: ArrayBuffer): ArrayBuffer => {
  const clonedBuffer = buffer.slice(0);
  return clonedBuffer;
};

const parseBlobToPDF = async (content: Blob): Promise<IFilePreview> => {
  const arrayBuffer = await content.arrayBuffer();
  return { content: arrayBuffer };
};

const parseBlobToJSON = async (content: Blob): Promise<IFilePreview> => {
  const codeText = await content.text();
  try {
    const jsonCode = JSON.parse(codeText);
    const codeToSet = JSON.stringify(jsonCode, null, 2);
    return { content: codeToSet, displayLanguage: SupportedViewerLanguage.JSON };
  } catch (error) {
    return { content: codeText, displayLanguage: SupportedViewerLanguage.PLAIN_TEXT };
  }
};

export const parseFileContentToViewer = async (
  fileContent: string | Blob,
): Promise<IFilePreview> => {
  const isBlobType = fileContent instanceof Blob;

  const mimeTypeToEditorLanguageMap = {
    [MIMETypes.TXT]: SupportedViewerLanguage.PLAIN_TEXT,
    [MIMETypes.CSV]: SupportedViewerLanguage.PLAIN_TEXT,
    [MIMETypes.HTML]: SupportedViewerLanguage.HTML,
    [MIMETypes.MD]: SupportedViewerLanguage.MARKDOWN,
    [MIMETypes.XML]: SupportedViewerLanguage.XML,
  };

  if (!isBlobType)
    return { content: fileContent, displayLanguage: SupportedViewerLanguage.PLAIN_TEXT };
  if (fileContent.type.includes(MIMETypes.JSON)) return parseBlobToJSON(fileContent);

  // Safari includes the charset in the mime type
  const [baseMimeType] = fileContent.type.split(';') ?? [];

  if (Object.keys(mimeTypeToEditorLanguageMap).some((type) => baseMimeType.includes(type))) {
    return {
      content: await fileContent.text(),
      displayLanguage:
        mimeTypeToEditorLanguageMap[baseMimeType as keyof typeof mimeTypeToEditorLanguageMap],
    };
  }

  return parseBlobToPDF(fileContent);
};

export const pngToPDF = (
  image: string,
  fileName: string,
  imageSize: {
    width: number;
    height: number;
  },
) => {
  const pdf = new JsPDF();

  const { width: imageWidth, height: imageHeight } = imageSize;
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const imgAspectRatio = imageWidth / imageHeight;

  let maxPdfImageWidth = pdfWidth;
  let maxPdfImageHeight = pdfWidth / imgAspectRatio;

  // Adjust if img height exceeds PDF dimensions
  if (maxPdfImageHeight > pdfHeight) {
    maxPdfImageHeight = pdfHeight;
    maxPdfImageWidth = maxPdfImageHeight * imgAspectRatio;
  }

  const xPosition = (pdfWidth - maxPdfImageWidth) / 2;
  const yPosition = 0;

  pdf.addImage(image, 'PNG', xPosition, yPosition, maxPdfImageWidth, maxPdfImageHeight);
  pdf.save(fileName);
};

// Base64 methods
const base64ToBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const fileToBase64 = (
  file: File,
  callback: (base64: string | ArrayBuffer | null) => void,
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => callback(reader.result);
};

export const extractBase64MimeType = (base64: string): string | null => {
  const supportedSignatures: { [key: string]: string } = {
    '/9j/': MIMETypes.JPEG, // JPEG
    iVBORw0KGgo: MIMETypes.PNG, // PNG
    PHN2Z: MIMETypes.SVG, // SVG
  };

  for (const signature in supportedSignatures) {
    if (base64.startsWith(signature)) return supportedSignatures[signature];
  }

  return null;
};

export const extractBase64Filename = (base64: string): string | null => {
  const mimeType = extractBase64MimeType(base64);
  if (mimeType) return `logo.${mimeType.split('/')[1]}`;
  return null;
};

export const base64ToFile = (base64: string): File | null => {
  const buffer = base64ToBuffer(base64);
  const mimeType = extractBase64MimeType(base64);
  const filename = extractBase64Filename(base64);

  if (!mimeType || !filename) return null;

  return new File([buffer], filename, { type: mimeType });
};

export const base64ToUploadFile = (base64: string): UploadFile | null => {
  const file = base64ToFile(base64);

  if (!file) return null;

  const fileSrc = `data:${file.type};base64,${base64}`;

  return {
    uid: '-1',
    name: file.name,
    status: 'done',
    originFileObj: file,
    preview: fileSrc,
  } as UploadFile;
};
