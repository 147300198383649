import { UploadFileType } from '@constants/constant';
import { NUMBER_OF_FILES_PER_PAGE } from '@src/constants/common';
import { getWorkspace } from '@src/utils/store';
import { http } from './base-http';

/* Get workspace document files list */
export interface IAllFilesData {
  limit?: number;
  after?: {
    value: string;
    fileId: string;
  };
  page_number?: number;
  name?: string;
  field?: string;
  order?: string;
  filters?: string;
}

export interface IAllEvalData {
  limit?: number;
  after?: string[];
  page_number?: number;
  name?: string;
}

export function getWorkspaceFilesApi(
  params?: IAllFilesData,
  workspaceName: string = getWorkspace(),
) {
  const paramsMap = {
    limit: params?.limit || NUMBER_OF_FILES_PER_PAGE,
    ...(params?.after?.value && { after_value: params?.after.value }),
    ...(params?.after?.fileId && { after_file_id: params?.after.fileId }),
    ...(params?.name && { name: params?.name }),
    ...(params?.field && { field: params?.field }),
    ...(params?.order && { order: params?.order }),
    ...(params?.filters && { filter: params.filters }),
  };
  return http.get(`/api/v1/workspaces/${workspaceName}/files`, {
    params: paramsMap,
    paramsSerializer: { indexes: null },
  });
}

/* Delete workspace files */
export const deleteWorkspaceFilesApi = (
  file_id: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.delete(`/api/v1/workspaces/${workspaceName}/files/${file_id}`);
};

/* Delete all files */
export const deleteAllWorkspaceFilesApi = (workspaceName: string = getWorkspace()) => {
  return http.delete(`/api/v1/workspaces/${workspaceName}/files`);
};

/* Get file */
export const getFileApi = (file_id: string, workspaceName: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/files/${file_id}`);
};

/* Get file meta */
export const getFileMetaApi = (file_id: string, workspaceName: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/files/${file_id}/meta`);
};

/* Update file meta */
export const updateFileMetaApi = (
  file_id: string,
  data: Record<string, unknown>,
  workspaceName: string = getWorkspace(),
) => {
  return http.patch(`/api/v1/workspaces/${workspaceName}/files/${file_id}/meta`, data);
};

/* Get document for a file */
export const getDocFileApi = (file_id: string, workspaceName: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/files/${file_id}/documents`);
};

/* File warnings */
export const getFileWarningsApi = (workspaceName: string = getWorkspace()) => {
  return http.get(`api/v1/workspaces/${workspaceName}/invalid_files`);
};

/* Get File as blob */
export const getFileBlobApi = (fileId: string, workspace: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspace}/files/${fileId}`, { responseType: 'blob' });
};

/* Get File converted to PDF as blob */
export const getFilePDFConversionBlobApi = (fileId: string, workspace: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspace}/files/${fileId}/convert-to-pdf`, {
    responseType: 'blob',
  });
};

export const getFilePreviewBlobByTypeApi = (
  fileId: string,
  fileType: UploadFileType | null,
  workspace: string = getWorkspace(),
) => {
  const fileTypeForPDFConversion = [
    UploadFileType.docx,
    UploadFileType.doc,
    UploadFileType.xlsx,
    UploadFileType.xls,
  ];

  if (fileType && fileTypeForPDFConversion.includes(fileType))
    return getFilePDFConversionBlobApi(fileId, workspace);

  return getFileBlobApi(fileId, workspace);
};

/* Get workspace evalsets */
export function getWorkspaceEvalsetsApi(
  params?: IAllEvalData,
  workspaceName: string = getWorkspace(),
) {
  const paramsMap = {
    limit: params?.limit || NUMBER_OF_FILES_PER_PAGE,
    ...(params?.after && { after: params?.after }),
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.name && { name: params?.name }),
  };

  return http.get(`/api/v1/workspaces/${workspaceName}/evaluation_sets`, { params: paramsMap });
}

/* Delete workspace evalset */
export const deleteWorkspaceEvalsetApi = (
  evalsetName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.delete(`/api/v1/workspaces/${workspaceName}/evaluation_sets/${evalsetName}`);
};

/* Get evalset */
export const getEvalsetApi = (evalsetName: string, workspaceName: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/evaluation_sets/${evalsetName}`);
};

/* Get evalset CSV */
export const getEvalsetCSVApi = (evalsetName: string, workspaceName: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/evaluation_sets/${evalsetName}/csv`, {
    responseType: 'blob',
  });
};

/* Import evalset */
export function importEvalsetApi(
  payload: any,
  config: any,
  workspaceName: string = getWorkspace(),
) {
  return http.post(`/api/v1/workspaces/${workspaceName}/evaluation_sets/import`, payload, config);
}

export const getSampleDatasetsApi = (workspaceName: string = getWorkspace()) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/datasets`);
};

export const importSampleDatasetApi = (dataset: string, workspaceName: string = getWorkspace()) => {
  return http.post(`/api/v1/workspaces/${workspaceName}/datasets/${dataset}`);
};
