import { PipelineTemplatesCategory } from '@redux/types/types';
import { getODataEQFilterFrom } from './odata';

export const TEMPLATE_CATEGORY_TAGS = {
  recommended: 'category:recommended',
  documentSearch: 'category:document search',
  basicQA: 'category:basic qa',
  advancedQA: 'category:advanced qa',
  visualQA: 'category:visual qa',
  conversational: 'category:conversational',
  textAnalysis: 'category:text analysis',
  textToSQL: 'category:text to sql',
  imageGeneration: 'category:image generation',
};

export const TEMPLATES_TAGS = {
  questionAnswering: 'question answering',
  documentSearch: 'document search',
  chat: 'chat',
};

export const removeTemplatesTagPrefixes = (tag: string) => {
  const [, tagWithoutPrefix] = tag.split(':');
  return tagWithoutPrefix || tag;
};

export const getTagsNameFilter = (tagName: string) => {
  return `tags/any(tag: ${getODataEQFilterFrom('tag/name', tagName)})`;
};

const getRecommendedTemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.recommended);
};

const getDocumentSearchTemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.documentSearch);
};

const getBasicQATemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.basicQA);
};

const getAdvancedQATemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.advancedQA);
};

const getVisualQATemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.visualQA);
};

const getConversationalTemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.conversational);
};

const getTextAnalysisTemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.textAnalysis);
};

const getTextToSQLTemplateFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.textToSQL);
};

const getImageGenerationTemplatesFilter = () => {
  return getTagsNameFilter(TEMPLATE_CATEGORY_TAGS.imageGeneration);
};

export const getTemplatesByDeepsetCloudVersionFilter = (deepsetCloudVersion: string) => {
  return getODataEQFilterFrom('deepset_cloud_version', deepsetCloudVersion);
};

export const getTemplateFilterByCategory = (category: PipelineTemplatesCategory) => {
  const filtersByCategoryMap = {
    [PipelineTemplatesCategory.RECOMMENDED]: getRecommendedTemplateFilter,
    [PipelineTemplatesCategory.DOCUMENT_SEARCH]: getDocumentSearchTemplateFilter,
    [PipelineTemplatesCategory.BASIC_QA]: getBasicQATemplateFilter,
    [PipelineTemplatesCategory.ADVANCED_QA]: getAdvancedQATemplateFilter,
    [PipelineTemplatesCategory.CONVERSATIONAL]: getConversationalTemplateFilter,
    [PipelineTemplatesCategory.TEXT_ANALYSIS]: getTextAnalysisTemplateFilter,
    [PipelineTemplatesCategory.VISUAL_QA]: getVisualQATemplateFilter,
    [PipelineTemplatesCategory.TEXT_TO_SQL]: getTextToSQLTemplateFilter,
    [PipelineTemplatesCategory.IMAGE_GENERATION]: getImageGenerationTemplatesFilter,
  };

  return filtersByCategoryMap[category]();
};
