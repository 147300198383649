import React from 'react';
import { Spin } from 'antd';

import styles from './loadingIndicator.module.scss';

interface ILoadingIndicatorProps {
  size?: 'small' | 'large' | 'default';
}

const LoadingIndicator = ({ size = 'large' }: ILoadingIndicatorProps) => (
  <div className={styles.loadingIndicator}>
    <Spin size={size} />
  </div>
);

export default LoadingIndicator;
