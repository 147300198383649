/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { CSSProperties, memo, useCallback } from 'react';
import Markdown, { Components } from 'react-markdown';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Flex, Tooltip, Typography } from 'antd';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import stripJsonComments from 'strip-json-comments';
import { parseTableDataToCsvString } from '@utils/data';
import { downloadBlobFile } from '@utils/file';
import useExtractDataFromNodes, { ExtractorType, TableData } from '@hooks/useExtractDataFromNodes';
import { DOWNLOAD_TABLE_BUTTON_LABEL, TABLE_LABEL } from '@constants/common';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import { ArtifactFactory } from '@modules/Artifacts/ArtifactFactory';
import { ArtifactType } from '@modules/Artifacts/types/artifacts';
import styles from './markdownViewer.module.scss';

const { Title } = Typography;

interface IMarkdownViewerProps {
  children: string;
  customComponents?: Components;
  customCodeStyle?: Record<string, CSSProperties>;
}

export const LinkRender = ({
  href,
  children,
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const CodeRender = ({
  className,
  children,
  customStyle = {},
  ...rest
}: CodeProps & { customStyle: { [key: string]: CSSProperties } }) => {
  const [, language] = /language-(\w+)/.exec(className || '') || [];

  if (!language)
    return (
      <code {...rest} className={className}>
        {children}
      </code>
    );

  if (language === 'artifacts') {
    try {
      const artifactData = JSON.parse(stripJsonComments(children[0] as string));
      const artifact = ArtifactFactory.create(
        ArtifactType.CHART,
        artifactData.data,
        artifactData.metadata,
      );
      return artifact.render();
    } catch {
      return (
        <Flex justify="center" align="center" className={styles.loadingContainer}>
          <LoadingIndicator size="default" />
        </Flex>
      );
    }
  }

  const style = {
    ...coy,
    ...customStyle,
  };

  return (
    <SyntaxHighlighter style={style} language={language} PreTag="div">
      {children as string}
    </SyntaxHighlighter>
  );
};

export const TableRender = ({ children, ...props }: React.HTMLProps<HTMLTableElement>) => {
  const { headers, rows } = useExtractDataFromNodes(children, ExtractorType.TABLE) as TableData;

  const generateTableTitle = () => {
    if (!headers.length) return TABLE_LABEL;
    return headers.slice(0, 3).join(', ');
  };

  const handleDownloadAsCsv = () => {
    const tableTitle = generateTableTitle();
    const fileName = `${tableTitle.toLowerCase().replace(/[^a-z0-9]/g, '_')}.csv`;

    const csvData = parseTableDataToCsvString(
      headers.map((header) => ({ dataIndex: header, key: header, title: header })),
      rows,
    );
    downloadBlobFile(fileName, csvData);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <Title level={5}>{generateTableTitle()}</Title>
        <Tooltip title={DOWNLOAD_TABLE_BUTTON_LABEL} placement="top">
          <Button
            type="text"
            icon={<DownloadOutlined />}
            onClick={handleDownloadAsCsv}
            size="small"
          />
        </Tooltip>
      </div>
      <table {...props}>{children}</table>
    </div>
  );
};

const MarkdownViewer = memo(
  ({ children, customComponents = {}, customCodeStyle = {} }: IMarkdownViewerProps) => {
    const codeRenderer = useCallback(
      (props: CodeProps) => <CodeRender {...props} customStyle={customCodeStyle} />,
      [],
    );

    return (
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: LinkRender,
          code: codeRenderer,
          table: TableRender,
          ...customComponents,
        }}
        className={styles.container}
      >
        {children}
      </Markdown>
    );
  },
);

export default MarkdownViewer;
