import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message as antMessage, Button, Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import { getFileExtension } from '@utils/common';
import { interpolateString } from '@utils/string';
import { HELP_LABEL } from '@constants/common';
import { FILE_TYPE_TO_MIME_MAP, UploadFileType } from '@constants/constant';
import {
  FILE_SIZE_LIMIT_ERROR_WITH_DOCUMENTATION,
  FILE_SIZE_LIMIT_ERROR_WITH_DOCUMENTATION_LABEL,
} from '@constants/data-page';
import {
  FILE_TYPE_ERROR_ALERT,
  UPLOAD_BUTTON,
  UPLOAD_CANCEL_BUTTON,
  UPLOAD_DESCRIPTION,
  UPLOAD_HINT,
  UPLOAD_MODAL_TITLE,
} from '@constants/upload';
import { addUploadFiles, startUploading } from '@redux/actions/uploadActions';
import FileUploadList from '@components/uploadData/fileUploadList/FileUploadList';
import UploadFiles from '@components/uploadFiles/UploadFiles';
import UpgradeBanner from '@modules/Upgrade/components/upgradeBanner/UpgradeBanner';
import { FREE_TIER_LIMITS } from '@modules/Upgrade/consts/pricing';
import useFreeLimits from '@modules/Upgrade/hooks/useFreeLimits';
import styles from './uploadFileModal.module.scss';

const RENDER_LIMIT = 50;
const FILE_SIZE_LIMIT_MB = 200;
const DOCS_SDK_URL = import.meta.env.VITE_DOCS_SDK_URL;

interface IUploadFileModalProps {
  title?: string;
  multiple: boolean;
  showUploadList: boolean;
  fileTypes?: UploadFileType[];
  helpLink?: string;
  onUpload: (...args: any) => Promise<unknown>;
  onCancel: () => void;
  afterUpload: (...args: unknown[]) => void;
  uploadDescriptionMessage?: string;
  showHintDescription?: boolean;
  showFileSizeLimitMessage?: boolean;
}

const UploadFileModal = ({
  title = UPLOAD_MODAL_TITLE,
  fileTypes,
  helpLink,
  onUpload,
  onCancel,
  afterUpload,
  uploadDescriptionMessage,
  showHintDescription,
  showFileSizeLimitMessage,
}: IUploadFileModalProps) => {
  const dispatch = useDispatch();
  const { isLimitedUser, totalFiles } = useFreeLimits();
  const [currentFileList, setCurrentFileList] = useState<UploadFile[]>([]);
  const [listLength, setListLength] = useState(RENDER_LIMIT);
  const [fileExceedsSizeLimit, setFileExceedsSizeLimit] = useState(false);

  const isLimitExceed =
    isLimitedUser && totalFiles + currentFileList.length > FREE_TIER_LIMITS.FILES;

  const onConfirmUpload = () => {
    dispatch(
      addUploadFiles({
        files: [...currentFileList],
        uploadFn: onUpload,
        afterUploadFn: afterUpload,
      }),
    );
    dispatch(startUploading);
    onCancel();
  };

  const getSupportedFileTypesMessage = (text: string) => {
    if (!fileTypes) return '';
    return interpolateString(text, {
      fileFormats: fileTypes
        .map((fileType) => fileType.toUpperCase())
        .reduce((acc, curr, idx, list) => {
          if (idx === 0) return curr;
          else if (idx === list.length - 1) return `${acc}, and ${curr}`;

          return `${acc}, ${curr}`;
        }, ''),
    });
  };

  const getFormattedHintText = (text: string) => {
    const fileTypesHint = getSupportedFileTypesMessage(text);
    const sizeLimitHint = interpolateString(FILE_SIZE_LIMIT_ERROR_WITH_DOCUMENTATION, {
      sizeLimit: FILE_SIZE_LIMIT_MB,
      documentationLink: (
        <a href={DOCS_SDK_URL} target="_blank" rel="noreferrer">
          {FILE_SIZE_LIMIT_ERROR_WITH_DOCUMENTATION_LABEL}
        </a>
      ),
    });

    if (showHintDescription && !showFileSizeLimitMessage) return fileTypesHint;
    if (!showHintDescription && showFileSizeLimitMessage && fileExceedsSizeLimit)
      return sizeLimitHint;
    if (showHintDescription && showFileSizeLimitMessage && fileExceedsSizeLimit)
      return (
        <>
          <div>{fileTypesHint}</div>
          <div>{sizeLimitHint}</div>
        </>
      );
    return '';
  };

  const isValidFileFormat = (file: File) => {
    let isValidFileExtesion = true;

    if (!fileTypes) return isValidFileExtesion;

    if (file.type) {
      isValidFileExtesion = !!fileTypes.find(
        (fileType) => file.type === FILE_TYPE_TO_MIME_MAP[fileType],
      );
    } else {
      const fileExtension = getFileExtension(file);
      isValidFileExtesion = !!fileTypes.find((fileType) => fileExtension === fileType);
    }

    return isValidFileExtesion;
  };

  const createCurrentList = (files: FileList) => {
    const chosenFiles = Array.prototype.slice.call(files);

    const filteredFiles = chosenFiles.filter((file: File, index: number) => {
      if (currentFileList.find((currentFile) => currentFile.name === file.name)) return false;

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > FILE_SIZE_LIMIT_MB) setFileExceedsSizeLimit(true);

      if (!isValidFileFormat(file)) {
        antMessage.error(getSupportedFileTypesMessage(FILE_TYPE_ERROR_ALERT));
        return false;
      }
      chosenFiles[index].uid = uuidv4();
      return true;
    });

    setCurrentFileList([...currentFileList, ...filteredFiles]);
  };

  const getInputAcceptAttributeFromFileTypes = () => {
    if (!fileTypes) return '';
    return fileTypes.map((fileType) => `.${fileType}`).join(', ');
  };

  return (
    <Modal
      data-testid="uploadModal"
      open
      title={title}
      destroyOnClose
      centered
      maskClosable={false}
      className={styles.uploadModal}
      okText={UPLOAD_BUTTON}
      cancelText={UPLOAD_CANCEL_BUTTON}
      onOk={onConfirmUpload}
      onCancel={onCancel}
      okButtonProps={{ disabled: currentFileList.length === 0 }}
      footer={
        <div className={styles.footer} style={!helpLink ? { justifyContent: 'flex-end' } : {}}>
          {helpLink && (
            <Button
              type="link"
              target="_blank"
              href={helpLink}
              icon={<QuestionCircleOutlined />}
              style={{ paddingLeft: 0 }}
            >
              {HELP_LABEL}
            </Button>
          )}
          <div className={styles.footer_wrapper}>
            <Button onClick={onCancel}>{UPLOAD_CANCEL_BUTTON}</Button>
            <Button
              type="primary"
              data-testid="uploadModal_uploadFile_button"
              onClick={onConfirmUpload}
              disabled={currentFileList.length === 0 || isLimitExceed}
            >
              {UPLOAD_BUTTON}
            </Button>
          </div>
        </div>
      }
    >
      {/* TODO: Change and move text to const when we have removing single file from list feature */}
      {isLimitExceed && (
        <UpgradeBanner
          label={`Your plan only allows for ${FREE_TIER_LIMITS.FILES} file uploads. There are ${
            totalFiles + currentFileList.length - FREE_TIER_LIMITS.FILES
          } files above the limit in the list.`}
        />
      )}
      <UploadFiles
        createCurrentList={createCurrentList}
        buttonDescription={uploadDescriptionMessage || UPLOAD_DESCRIPTION}
        hintDescription={
          showHintDescription || fileExceedsSizeLimit ? getFormattedHintText(UPLOAD_HINT) : ''
        }
        getInputAcceptAttributeFromFileTypes={getInputAcceptAttributeFromFileTypes}
        size="large"
        expanded
      />

      <div className={styles.uploadFileList}>
        <FileUploadList
          filesList={currentFileList}
          listLength={listLength}
          viewOnly
          onLoadMore={() => setListLength(listLength + RENDER_LIMIT)}
          fileSizeLimitMB={FILE_SIZE_LIMIT_MB}
        />
      </div>
    </Modal>
  );
};

export default UploadFileModal;
